import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

export const Section = (props) => <section {...props} />;

export const Container = (props) => {
  const className = classNames('container', props.className);
  return <div {...props} className={className} />;
};

export const ButtonSizes = { small: 'small', medium: 'medium', large: 'large' };

export const Button = (props) => {
  const tProps = { ...props };

  const classOpts = {
    'pure-button': true,
  };

  [
    'active',
    'disabled',
    'primary',
    'success',
    'error',
    'warning',
    'secondary',
  ].forEach((attr) => {
    if (attr in tProps) {
      classOpts[`pure-button-${attr}`] = tProps[attr];
      delete tProps[attr];
    }
  });

  if (props.size in ButtonSizes) {
    classOpts[`pure-button-${ButtonSizes[props.size]}`] =
      ButtonSizes[props.size];
    delete tProps[props.size];
  }

  tProps.className = classNames(classOpts, tProps.className);

  switch (tProps.type) {
    case Button.TYPE_ROUTE: {
      return <Link {...tProps} />;
    }
    case Button.TYPE_LINK: {
      tProps.href = tProps.href || '#';
      return <a {...tProps} />;
    }
    default: {
      tProps.type = tProps.type || 'button';
      return <button {...tProps} />;
    }
  }
};

Object.assign(Button, {
  TYPE_LINK: 'link',
  TYPE_ROUTE: 'route',
});

Button.types = [Button.TYPE_LINK, Button.TYPE_ROUTE, 'button', 'submit'];

export const PrimaryButton = (props) => <Button {...props} primary={true} />;

export const Form = (props) => {
  const classOpts = {
    'pure-form': true,
  };
  ['stacked', 'aligned'].forEach((attr) => {
    if (props[attr]) {
      classOpts[`pure-form-${attr}`] = attr;
    }
  });
  return (
    <form
      className={classNames(props.className, classOpts)}
      onSubmit={props.onSubmit}
    >
      {props.children}
    </form>
  );
};

// just in case these need to be updated in a centralized place...
export const Input = (props) => {
  let tProps = Object.assign({}, props);
  if ('hasError' in tProps) {
    if (tProps.hasError) {
      tProps.className = classNames(tProps.className, 'has-error');
    }
    delete tProps.hasError;
  }
  return <input type="text" {...tProps} />;
};

export const Label = (props) => <label {...props} />;

export const FormBody = (props) => {
  const className = classNames(props.className, 'form-body');
  return <div {...props} className={className} />;
};

export const FormGroup = (props) => {
  const className = classNames(props.className, 'form-group');
  return <div {...props} className={className} />;
};

export const FormButtons = (props) => {
  const tProps = Object.assign({}, props);
  let doReverse = true;
  if ('reverse' in tProps) {
    doReverse = tProps.reverse !== false;
    delete tProps.reverse;
  }
  const className = classNames(props.className, 'form-buttons', 'split-items', {
    'split-items-reverse': doReverse,
  });
  return <div {...tProps} className={className} />;
};

export const CardSection = (props) => {
  const className = classNames(props.className, 'card-section');
  return <div {...props} className={className} />;
};

export const CardSectionMinimal = (props) => (
  <CardSection
    {...props}
    className={classNames(props.className, 'card-section-minimal')}
  />
);
