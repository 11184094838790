import { Hub } from '@aws-amplify/core';
import PropTypes from 'prop-types';
import querystring from 'querystring';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect, Route } from 'react-router-dom';

import { STATE_SIGNEDIN, withAuthUser } from '../auth/AuthProvider';
import { SignInButton } from '../auth/Buttons';
import { getRedirUrl } from '../auth/utils';
import LoaderDots from '../components/LoaderDots';
import connectExtension from '../extension/Provider';
import { log } from '../logger';
import { getTitle } from '../utils/document';

class AuthPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired, // from react router Route
    extensionGetHandshake: PropTypes.func.isRequired, // from connectExtension
  };

  constructor(props) {
    super(props);

    Hub.listen('auth', this.onHubCapsule);
    this.state = {
      doRedirect: false,
      showMessage: false,
      explicitRedirect: undefined,
    };
    this.didCognitoHostedUI = true;
  }

  // life-cycle
  componentDidMount() {
    this._mounted = true;
    window.setTimeout(() => {
      if (this._mounted) {
        this.setState({ showMessage: true });
      }
    }, 3000);
  }

  componentWillUnmount() {
    this._mounted = false;
    Hub.remove('auth', this.onHubCapsule);
  }

  // hub listener
  onHubCapsule = (capsule) => {
    const { channel, payload } = capsule;
    if (channel === 'auth') {
      log.group(`[AuthPage.onHubCapsule.auth] ${payload.event}`);
      log.debug('location.href', window.location.href);
      log.debug('channel', channel);
      log.groupCollapsed('payload');
      log.debug('username?', payload.data?.username);
      log.debug(log.j(payload));
      log.groupEnd();
      log.debug('props.authState', this.props.authState);
      log.debug('didCognitoHostedUI', this.didCognitoHostedUI);
      log.groupEnd();
      switch (payload.event) {
        case 'customOAuthState':
          const redirectPath = new URLSearchParams(payload.data).get(
            'redirectPath',
          );
          if (redirectPath) {
            this.setState({ ...this.state, explicitRedirect: redirectPath });
          }
          break;

        case 'cognitoHostedUI': {
          // wait for this event to trigger the redirect
          if (
            payload.data?.username &&
            this.props.authState !== STATE_SIGNEDIN
          ) {
            // record in non-reactive state that we have witnessed this event
            // so once we login we can do the redirect
            // NOTE: we do not want to redirect to other components
            // in a "signIn" state, when we'll momentarily be "signedIn",
            // e.g., the login popup auto-redirects to the hosted UI
            // when logged out
            this.didCognitoHostedUI = true;
          } else {
            // something went wrong? no user info... redirect now?
            log.warn(
              `[AuthPage] missing user info (${this.props.authState}) ${channel}:${payload.event}`,
              payload,
            );
            this.setState({ doRedirect: true });
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  shouldDoRedirect() {
    return (
      this.state.doRedirect ||
      (this.didCognitoHostedUI && this.props.authState === STATE_SIGNEDIN)
    );
  }

  // render
  render() {
    const { match } = this.props;

    // NOTE - these match with or withhout trailing slash
    // since no `exact` params
    return (
      <>
        <Helmet>
          <title>{getTitle('Auth')}</title>
        </Helmet>
        <Route path={`${match.path}/signin`} render={this.renderSignIn} />
        <Route path={`${match.path}/signout`} render={this.renderSignOut} />
      </>
    );
  }

  renderSignIn = () => {
    const { showMessage, explicitRedirect } = this.state;

    // wait for the proper Hub events for OAuth setup to complete
    const redirUrl = explicitRedirect ?? getRedirUrl();
    log(
      `[AuthPage.renderSignIn] redirUrl=${redirUrl}, token=${document.cookie
        .split('; ')
        .find((tok) => tok.startsWith(getRedirUrl.COOKIE_KEY + '='))}`,
    );
    if (this.shouldDoRedirect()) {
      return <Redirect to={redirUrl} />;
    } else {
      const qs = querystring.parse(window.location.search.substring(1));
      if (qs.error && qs.error_description) {
        // TODO(security) - this is so ripe for rude people to take advantage of...
        return (
          <section>
            <div className="container text-left">
              <p>Encountered the error: {qs.error_description}</p>
              <p>Please try again…</p>
              <p>
                <SignInButton>Sign in</SignInButton>
              </p>
            </div>
          </section>
        );
      } else {
        return (
          <section>
            <div className="container text-center">
              <br />
              <br />
              <LoaderDots />
              <br />
              <br />
              <br />
              <br />
              {showMessage && (
                <span className="dim">
                  Updating logged-in state. Go to{' '}
                  <Link
                    to={'/'}
                    className="dim"
                    style={{ textDecoration: 'underline' }}
                  >
                    home
                  </Link>{' '}
                  if this is taking too long.
                </span>
              )}
            </div>
          </section>
        );
      }
    }
  };

  renderSignOut = () => {
    // do the extension logout
    this.props
      .extensionGetHandshake()
      .then((child) => {
        child.call('login', {}); // log the user out
      })
      .catch((err) => {
        log.error('Unable to log out of extension', err);
      });

    // instantly redirect signOut urls
    const redirUrl = getRedirUrl();
    return <Redirect to={redirUrl} />;
  };
}

export default withAuthUser(connectExtension(AuthPage));
